//
// Scaffolding
// --------------------------------------------------

html,
body {
  height: 100%;
}

// Push footer down toward bottom in all cases
body {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
}

main {
  height: 100%;
  margin-bottom: 1rem;
}

h1 {
  margin-bottom: 1rem;
  font-weight: bold;
}
