//
// Footer
// --------------------------------------------------

footer {
  flex-shrink: 0;
  padding: 1.25rem 0.5rem;
  margin-top: auto;
  font-size: 14px;
  text-align: center;
  background: $footer-bg;
}

.footer-disclaimer {
  margin-top: 1rem;

  > p {
    margin: 0;
  }
}

.footer-items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0;
  list-style: none;

  .btn {
    // stylelint-disable scss/at-extend-no-missing-placeholder
    @extend .btn-sm;
    @extend .btn-outline-dark;
    // stylelint-enable scss/at-extend-no-missing-placeholder
  }

  @media (min-width: 505px) {
    flex-direction: row;
  }
}
