//
// Forms
// --------------------------------------------------

.control-range {
  max-width: 600px;
  margin-bottom: 0.5rem;
  text-align: left;

  label {
    width: 100%;
    margin-bottom: 0;
  }
}
