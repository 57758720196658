//
// Header
// --------------------------------------------------

$header-xs-break: 350px;

header {
  margin-bottom: 1.5rem;
  background: $header-bg;
  border-bottom: 1px solid $header-border-color;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.75rem;

    @media (min-width: $header-xs-break) {
      flex-direction: row;
    }
  }

  .brand-link {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    color: $header-color;
    text-decoration: none;

    &:hover {
      color: $header-color;
    }
  }

  .logo {
    width: 38px;
    height: 38px;
    margin-right: 0.5rem;
  }
}

.header-actions {
  display: flex;
  flex-direction: row;
  gap: 0.375rem;

  > .header-action {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-size: 20px;
    color: $header-color;
    text-decoration: none;
    background: none;
    border: 0;
    border-radius: 50%;
    transition: all 0.25s ease-out;

    &:hover {
      background: $header-action-hover-bg;
    }

    &:active,
    &:focus {
      background: $header-action-focus-bg;
    }

    &.theme-dark {
      transform: rotate(360deg);
    }
  }

  @media (min-width: $header-xs-break) {
    margin-left: auto;
  }
}
