//
// Dark theme
// --------------------------------------------------

$header-bg-dark: shade-color($primary, 80%);
$header-color-dark: $gray-300;
$header-action-hover-bg-dark: shade-color($primary, 60%);
$header-action-focus-bg-dark: shade-color($primary, 50%);

$footer-bg-dark: shade-color($primary, 85%);

$draft-player-border-dark: tint-color($body-bg-dark, 30%);
$draft-player-border-hover-dark: tint-color($body-bg-dark, 60%);
$draft-choice-border: $draft-player-border-hover-dark;

[data-bs-theme="dark"] {
  header {
    background: $header-bg-dark;

    .brand-link,
    .brand-link:hover,
    .header-action {
      color: $header-color-dark;
    }
  }

  .header-actions > .header-action {
    &:hover {
      background: $header-action-hover-bg-dark;
    }

    &:active,
    &:focus {
      background: $header-action-focus-bg-dark;
    }
  }

  .draft-settings {
    .form-control,
    .react-checkbox-tree {
      border-color: $draft-player-border-dark;

      &:hover {
        border-color: $draft-player-border-hover-dark;
      }
    }
  }

  .draft-results-players > li {
    border-color: $draft-player-border-dark;

    h3 {
      border-color: $draft-player-border-dark;
    }

    &:hover,
    &:hover h3 {
      border-color: $draft-player-border-hover-dark;
    }
  }

  .footer {
    background: $footer-bg-dark;

    .btn {
      // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
      @extend .btn-outline-light;
    }
  }
}
