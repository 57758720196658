//
// Draft settings
// --------------------------------------------------

.leader-box {
  display: flex;
  align-items: center;
}

.leader-icon {
  margin-right: 0.5rem;
  margin-left: -0.25rem;
}

.draft-settings {
  .draft-settings-duplications {
    margin-top: 1rem;
  }

  .react-checkbox-tree {
    padding: 0.25rem;
    border: 1px solid $draft-player-border;
    border-radius: $border-radius;

    &:hover {
      border-color: $draft-player-border-hover;
    }

    // Let's pretend this is not hierarchical tree
    .rct-collapse {
      display: none;
    }

    // On smaller widths, wrap the text more elegantly
    label {
      display: flex;
    }
  }
}

.draft-settings-players > ol {
  @include media-breakpoint-up(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  display: grid;
  grid-template-columns: 1fr;
  gap: 0.25rem;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  > li {
    padding: 0.5rem;
  }

  .form-control {
    width: 100%;
    margin-bottom: 1rem;
    border-color: $draft-player-border;

    &:hover {
      border-color: $draft-player-border-hover;
    }
  }
}
