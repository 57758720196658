//
// Light theme
// --------------------------------------------------

$header-bg: tint-color($primary, 97%);
$header-color: $dark;
$header-border-color: $primary;
$header-action-hover-bg: $gray-300;
$header-action-focus-bg: $gray-400;

// Stolen from Material UI
$draft-player-border: #c0bbb8;
$draft-player-border-hover: #20201f;
$draft-choice-border: $draft-player-border-hover;

$footer-bg: tint-color($primary, 92%);
