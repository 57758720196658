//
// Draft results
// --------------------------------------------------

.draft-results {
  margin-bottom: 2rem;

  > ul {
    margin-bottom: 0;
  }
}

.draft-results-options {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1rem;

  > button {
    margin-bottom: 1rem;
  }
}

.draft-results-players,
.draft-results-player-choices {
  padding-left: 0;
  list-style: none;
}

.draft-results-players {
  > li {
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border: 1px solid $draft-player-border;
    border-bottom-width: 2px;
    border-radius: $border-radius;

    &:hover,
    &:hover h3 {
      border-color: $draft-player-border-hover;
    }

    + li {
      @include media-breakpoint-up(lg) {
        margin-top: 0.5rem;
      }

      margin-top: 1rem;
    }
  }

  h3 {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 0.5rem;
      margin-bottom: 0;
      font-size: 18px;
      border-right: 2px solid $draft-player-border;
    }

    flex-shrink: 0;
    max-width: 400px;
    font-size: 24px;
    font-weight: bold;
  }
}

.draft-results-player-choices {
  @include media-breakpoint-up(lg) {
    padding: 0 0.5rem;
  }

  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 0.5rem;

  > li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.125rem 0.325rem 0.125rem 0.25rem;
    font-size: 18px;
    border: 1px solid $draft-choice-border;
    border-radius: 50rem;
  }

  img {
    margin-right: 0.25rem;
  }
}
