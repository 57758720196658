//
// Draft actions
// --------------------------------------------------

.draft-actions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  .btn-submit {
    width: 25%;
  }
}
