//
// Utilities
// --------------------------------------------------

.markdown-wrapper-inline {
  display: inline;
}

.strikethrough {
  text-decoration: line-through;
}
